// ** Auth Endpoints
export default {
  loginEndpoint: '/api/auth/token/obtain/',
  registerEndpoint: '/api/auth/token/obtain/',
  refreshEndpoint: '/api/auth/token/refresh/',
  logoutEndpoint: '/api/auth/logout/',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'access',
  storageRefreshTokenKeyName: 'refresh'
}
