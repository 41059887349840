// You can customize the template with the help of this file
/*eslint-disable */
//Template config options
const { REACT_APP_MARCA_BLANCA,REACT_APP_VERSION, REACT_APP_RECAPTCHA_KEY } = process.env;
const AppVersion = REACT_APP_VERSION
const themeConfigEuroaffiliatiLocal = {
  app: {
    appName: 'EuroAffiliati',
    appLogoImage: require('@src/assets/images/logo/scritta.svg').default,
    appLogoSmallImage: require('@src/assets/images/logo/logo.svg').default,
    appLandingPage: 'https://www.bonuswelcome.com/',
    AppVersion: AppVersion
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  },
  domain: {
    reactBaseUrl:'http://localhost:3000/',
    djangoBaseUrl:'http://localhost:8000/'
  }
}
const themeConfigEuroaffiliatiDevelop = {
  app: {
    appName: 'EuroAffiliati',
    appLogoImage: require('@src/assets/images/logo/scritta.svg').default,
    appLogoSmallImage: require('@src/assets/images/logo/logo.svg').default,
    appLandingPage: 'https://www.bonuswelcome.com/',
    AppVersion: AppVersion
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  },
  domain: {
    reactBaseUrl:'http://10.132.15.206:3000/',
    djangoBaseUrl:'http://10.132.15.206:8000/'
    // reactBaseUrl:'http://localhost:3000/',
    // djangoBaseUrl:'http://localhost:8000/'
  }
}
const themeConfigEuroaffiliatiDevelop2= {
  app: {
    appName: 'EuroAffiliati',
    appLogoImage: require('@src/assets/images/logo/scritta.svg').default,
    appLogoSmallImage: require('@src/assets/images/logo/logo.svg').default,
    appLandingPage: 'https://www.bonuswelcome.com/',
    AppVersion: AppVersion
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  },
  domain: {
    reactBaseUrl:'http://10.132.15.202:3000/',
    djangoBaseUrl:'http://10.132.15.202:8000/'
    // reactBaseUrl:'http://localhost:3000/',
    // djangoBaseUrl:'http://localhost:8000/'
  }
}
const themeConfigEuroaffiliatiTest = {
  app: {
    appName: 'EuroAffiliati',
    appLogoImage: require('@src/assets/images/logo/scritta.svg').default,
    appLogoSmallImage: require('@src/assets/images/logo/logo.svg').default,
    appLandingPage: 'https://www.bonuswelcome.com/',
    AppVersion: AppVersion
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  },
  domain: {
    reactBaseUrl:'http://dev.euroaffiliati.com/',
    djangoBaseUrl:'http://preprod.euroaffiliati.com/'
  }
}
//  dev -  https
const themeConfigEuroaffiliatiAU = {
  app: {
    appName: 'EuroAffiliati',
    appLogoImage: require('@src/assets/images/logo/scritta.svg').default,
    appLogoSmallImage: require('@src/assets/images/logo/logo.svg').default,
    appLandingPage: 'https://www.bonuswelcome.com/',
    AppVersion: AppVersion
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  },
  domain: {
    reactBaseUrl:'https://dev.euroaffiliati.com/',
    djangoBaseUrl:'https://preprod.euroaffiliati.com/'
  }
}
const themeConfigEuroaffiliatiTestGAE = {
  app: {
    appName: 'EuroAffiliatiGAE',
    appLogoImage: require('@src/assets/images/logo/scritta.svg').default,
    appLogoSmallImage: require('@src/assets/images/logo/logo.svg').default,
    appLandingPage: 'https://www.bonuswelcome.com/',
    AppVersion: AppVersion
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  },
  domain: {
    reactBaseUrl:'https://affiliatteaccess.oa.r.appspot.com/',
    djangoBaseUrl:'https://api-dot-affiliatteaccess.oa.r.appspot.com/'
  }
}
const themeConfigEuroaffiliati = {
  app: {
    appName: 'EuroAffiliati',
    appLogoImage: require('@src/assets/images/logo/scritta.svg').default,
    appLogoSmallImage: require('@src/assets/images/logo/logo.svg').default,
    appLandingPage: 'https://www.bonuswelcome.com/',
    AppVersion: AppVersion
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  },
  domain: {
    reactBaseUrl:'https://apq.euroaffiliati.com/',
    djangoBaseUrl:'https://app.euroaffiliati.com/'
  }
}
const themeConfigAffiliateRevolution = {
  app: {
    appName: 'AffiliateRevolution',
    appLogoImage: require('@src/assets/images/logo/affiliatrevolution/scritta.svg').default,
    appLogoSmallImage: require('@src/assets/images/logo/affiliatrevolution/logo.svg').default,
    appLandingPage: 'https://comparatore.affiliaterevolution.network/',
    AppVersion: AppVersion
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  },
  domain: {
    reactBaseUrl:'https://apq.affiliaterevolution.network/',
    djangoBaseUrl:'https://app.affiliaterevolution.network/'
  }
}
const themeConfigBetmedia = {
  app: {
    appName: 'MiTipster',
    appLogoImage: require('@src/assets/images/logo/betmedia/scritta.png').default,
    appLogoSmallImage: require('@src/assets/images/logo/betmedia/logo.png').default,
    appLandingPage: 'https://www.bonuswelcome.com/',
    AppVersion: AppVersion
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  },
  domain: {
    reactBaseUrl:'https://apq.mitipster.com/',
    djangoBaseUrl:'https://app.mitipster.com/'
  }
}
const themeConfigDeally = {
  app: {
    appName: 'Deally',
    appLogoImage: require('@src/assets/images/logo/deally/scritt-deally.svg').default,
    appLogoSmallImage: require('@src/assets/images/logo/deally/logo-deally.svg').default,
    appLandingPage: 'https://www.comparatore-deally.eu/',
    AppVersion: AppVersion
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  },
  domain: {
    reactBaseUrl:'https://apq.deally.eu/',
    djangoBaseUrl:'https://app.deally.eu/'
  }
}
const themeConfigGamingAffiliation = {
  app: {
    appName: 'GamingAffiliation',
    appLogoImage: require('@src/assets/images/logo/gaffiliation/scrittaGA.svg').default,
    appLogoSmallImage: require('@src/assets/images/logo/gaffiliation/logoGA.svg').default,
    appLandingPage: 'https://comparabonus-ga.com/',
    AppVersion: AppVersion
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  },
  domain: {
    reactBaseUrl:'https://apq.gamingaffiliation.it/',
    djangoBaseUrl:'https://app.gamingaffiliation.it/'
  }
}
const themeConfigBrasiliaConnect = {
  app: {
    appName: 'BrasiliaConnect',
    appLogoImage: require('@src/assets/images/logo/brasiliaconnect/brasiliaconnect.png').default,
    appLogoSmallImage: require('@src/assets/images/logo/brasiliaconnect/logo.png').default,
    appLandingPage: 'https://www.bonuswelcome.com/',
    AppVersion: AppVersion
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  },
  domain: {
    reactBaseUrl:'https://apq.brasiliaconnect.com/',
    djangoBaseUrl:'https://app.brasiliaconnect.com/'
  }
}
const themeConfigTipsterUtility = {
  app: {
    appName: 'TipsterUtility',
    appLogoImage: require('@src/assets/images/logo/tipsterutility/scritta.svg').default,
    appLogoSmallImage: require('@src/assets/images/logo/tipsterutility/logo.svg').default,
    appLandingPage: 'https://www.bonuswelcome.com/',
    AppVersion: AppVersion
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  },
  domain: {
    reactBaseUrl:'https://apq.tipsterutility.com/',
    djangoBaseUrl:'https://app.tipsterutility.com/'
  }
}
const themeConfigAffiliateAccess= {
  app: {
    appName: 'AffiliateAccess',
    appLogoImage: require('@src/assets/images/logo/affiliateaccess/scritta.svg').default,
    appLogoSmallImage: require('@src/assets/images/logo/affiliateaccess/logo.svg').default,
    appLandingPage: 'https://www.bonuswelcome.com/',
    AppVersion: AppVersion
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  },
  domain: {
    reactBaseUrl:'https://apq.affiliateaccess.com/',
    djangoBaseUrl:'https://app.affiliateaccess.com/'
  }
}
const themeConfigMonsterAffiliates= {
  app: {
    appName: 'MyMonsterAffiliates',
    appLogoImage: require('@src/assets/images/logo/mymonsteraffiliate/scritta.svg').default,
    appLogoSmallImage: require('@src/assets/images/logo/mymonsteraffiliate/logo.svg').default,
    appLandingPage: 'https://www.playnow.games/',
    AppVersion: AppVersion
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  },
  domain: {
    reactBaseUrl:'https://apq.mymonsteraffiliates.net/',
    djangoBaseUrl:'https://app.mymonsteraffiliates.net/'
  }
}
const themeConfigXyzNet= {
  app: {
    appName: 'XyzNet',
    appLogoImage: require('@src/assets/images/logo/affiliateaccess/scritta.svg').default,
    appLogoSmallImage: require('@src/assets/images/logo/affiliateaccess/logo.svg').default,
    appLandingPage: 'https://www.bonuswelcome.com/',
    AppVersion: AppVersion
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  },
  domain: {
    reactBaseUrl:'https://apq.xyznet.online/',
    djangoBaseUrl:'https://app.xyznet.online/'
  }
}
const themeConfigFreedomLegacy= {
  app: {
    appName: 'FreedomLegacy',
    appLogoImage: require('@src/assets/images/logo/freedomlegacy/scritta.svg').default,
    appLogoSmallImage: require('@src/assets/images/logo/freedomlegacy/logo.svg').default,
    appLandingPage: 'https://www.bonuswelcome.com/',
    AppVersion: AppVersion
  },
  layout: {
    isRTL: false,
    skin: 'dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  },
  domain: {
    reactBaseUrl:'https://apq.freedom23affiliate.com/',
    djangoBaseUrl:'https://app.freedom23affiliate.com/'
  }
}

let themeConfig = themeConfigEuroaffiliatiTest;
if (REACT_APP_MARCA_BLANCA === 'EuroAffiliatiLocal') {
  themeConfig = themeConfigEuroaffiliatiLocal;
}
if (REACT_APP_MARCA_BLANCA === 'EuroaffiliatiDevelop2') {
  themeConfig = themeConfigEuroaffiliatiDevelop2;
}
if (REACT_APP_MARCA_BLANCA === 'EuroAffiliatiDevelop') {
  themeConfig = themeConfigEuroaffiliatiDevelop;
}
if (REACT_APP_MARCA_BLANCA === 'EuroaffiliatiTestGAE') {
  themeConfig = themeConfigEuroaffiliatiTestGAE;
}
if ( REACT_APP_MARCA_BLANCA === 'EuroAffiliatiAU') {
  themeConfig = themeConfigEuroaffiliatiAU;
}
if ( REACT_APP_MARCA_BLANCA === 'EuroAffiliatiTest') {
  themeConfig = themeConfigEuroaffiliatiTest;
}
if (REACT_APP_MARCA_BLANCA === 'EuroAffiliati') {
  themeConfig = themeConfigEuroaffiliati;
}
if (REACT_APP_MARCA_BLANCA === 'Deally') {
  themeConfig = themeConfigDeally;
}
if (REACT_APP_MARCA_BLANCA === 'GamingAffiliation') {
  themeConfig = themeConfigGamingAffiliation;
}
if (REACT_APP_MARCA_BLANCA === 'TipsterUtility') {
  themeConfig = themeConfigTipsterUtility;
}
if (REACT_APP_MARCA_BLANCA === 'AffiliateAccess') {
  themeConfig = themeConfigAffiliateAccess;
}
if (REACT_APP_MARCA_BLANCA === 'Betmedia') {
  themeConfig = themeConfigBetmedia;
}
if (REACT_APP_MARCA_BLANCA === 'AffiliateRevolution') {
  themeConfig = themeConfigAffiliateRevolution;
}
if (REACT_APP_MARCA_BLANCA === 'MyMonsterAffiliates') {
  themeConfig = themeConfigMonsterAffiliates;
}
if (REACT_APP_MARCA_BLANCA === 'XyzNet') {
  themeConfig = themeConfigXyzNet;
}
if (REACT_APP_MARCA_BLANCA === 'FreedomLegacy') {
  themeConfig = themeConfigFreedomLegacy;
}
if (REACT_APP_MARCA_BLANCA === 'BrasiliaConnect') {
  themeConfig = themeConfigBrasiliaConnect;
}
export default themeConfig;
